import React, { useEffect, useState } from "react";
import Navbar from "../components/common/navbar/Navbar";
import TakeCharge from "../components/home/take_charge/TakeCharge";
import GetFood from "../components/home/get-food/GetFood";
import ViewCustomerStories from "../components/home/view-customer-stories/ViewCustomerStories";
import FoodDiscoveryNetwork from "../components/home/food_discovery_network/FoodDiscoveryNetwork";
import ExtraIngredients from "../components/home/extra_ingredients/ExtraIngredients";
import NoChargePanal from "../components/home/no_charge_panal/NoChargePanal";
import Footer from "../components/common/footer/Footer";
import ScrollToTop from "../components/common/scroll-icon/ScrollToTop";
import ChatIcon from "../components/common/chat/ChatIcon";
import GoToTop from "../components/common/scroll-icon/GoToTop";
import SeoComponent from "../SeoComponent";
import RequestDemo from "../components/common/request_demo_form/RequestDemo";
const Home = () => {
  const [modalIsOpenAtVeryFirst, setModalIsOpenAtVeryFirst] = useState(false);

  useEffect(() => {
    const form_variable = localStorage.getItem("_grecaptcha");

    if (!form_variable) {
      const timer = setTimeout(() => {
        setModalIsOpenAtVeryFirst(true);
      }, 10000); // 10000ms = 10 seconds

      return () => clearTimeout(timer); 
    }
  }, []);
  return (
    <div className="">
      <SeoComponent pageUrl="" businessTitle="FusionPOS" />
      <GoToTop />
      <Navbar />
      <TakeCharge />
      <GetFood />
      <ViewCustomerStories />
      <FoodDiscoveryNetwork />
      <ExtraIngredients />
      <NoChargePanal />
      <Footer />
      <ScrollToTop />
      <ChatIcon />
      <RequestDemo
        isOpen={modalIsOpenAtVeryFirst}
        onRequestClose={() => setModalIsOpenAtVeryFirst(false)}
      />
    </div>
  );
};

export default Home;
